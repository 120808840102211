import { ArticleTitle } from "../../components/Headers";
import { PageBody } from "../../components/PageBody";
import { Helmet } from "react-helmet-async";
import { GridDiv, GridDivContainer } from "../../components/GridDiv";
import { Link } from "react-router-dom";
import { ChartBarIcon, CpuChipIcon, DocumentTextIcon, FlagIcon } from "@heroicons/react/24/outline";
import { ArticleParagraph } from "../../components/Paragraphs";


export const SportsModelsHome = () => {
    return (
        <PageBody>
            <Helmet>
                <title>Predictive Sports Modelling | VizBadger</title>
                <meta name="description" content="Learn about predictive sports modeling, a method of using statistics and machine learning to predict sports outcomes. This page explains the concept and benefits, and provides an example of a Monte Carlo simulation system." />
                <meta name="keywords" content="Sports models, sports analytics, sports analysis, sports predictions, sports tip, predictive modelling, predictive sports modelling, sports data, sports prediction, sports, statistics, machine learning, predictive modeling, Monte Carlo simulation, probabilistic approach, league winner" />
                <meta property="og:title" content="Predictive Sports Modelling | VizBadger" />
                <meta property="og:type" content="article" />
                <meta property="og:url" content="https://www.vizbadger.com/sports-models" />
                <meta property="og:description" content="Learn about predictive sports modeling, a method of using statistics and machine learning to predict sports outcomes. This page explains the concept and benefits, and provides an example of a Monte Carlo simulation system." />
            </Helmet>

            <ArticleTitle
                title="Predictive Sports Modelling"
            />

            <ArticleParagraph
                lines={[
                    "If you are interested in sports and interested in sport statistics then you have probably thought about applying statistics to predict game outcomes. That can be as simple as studying the form of a team or whether a player has a good record against a team. This is fundamentally combining knowledge from statistics and historic events to make a guess, or prediction, about a future one.",
                    "Taking data and using machine learning and statistical modelling to do this is predictive sports modelling; it has applications for teams and recruitment as well as professional sports betting. Sports data is often held behind large paywalls and kept at a premium. Much of the work that you will see on this website uses the concepts of building out models that can be used to simulate sporting events and could easily be applied with access to the large historic data sets that exists for practically every sport at this point.",
                    "My english pool Monte Carlo simulation system, for example, builds out a version of the game programmatically to simulate the results between two made up players. It does, however, use statistics that could be recorded and calculated from real matches and 'plugged in' to the simulations to create a prediction of the results between those two players.",
                    "Variation of results is at the heart of sporting events so most models must account for this and take a probabilistic approach; Team A is X% likely to beat Team B. Simulating a sports model thousands of times can produce these probabilities and create a distribution of possible results that might occur. If you do this for every game, then you can do it for all the games in a league to try and pick the league winner."
                ]}
            />

            <GridDivContainer>
                <GridDiv
                    widthClasses="w-full sm:w-1/2"
                >
                    <Link
                        to="/sports-models/english-pool-monte-carlo-simulation"
                        className="sm:w-auto w-full border border-gray-200 p-4 shadow rounded-lg lg:mx-0 mt-4 sm:mt-6 flex justify-center items-center cursor-pointer hover:shadow-lg"
                    >
                        <CpuChipIcon className="h-7 w-7 mr-2 text-badger-green" />
                        <span className="mr-2 text-center text-black w-full">English Pool Monte Carlo Simulation</span>
                    </Link>
                </GridDiv>
                <GridDiv
                    widthClasses="w-full sm:w-1/2"
                >
                    <Link
                        to="/sports-models/sports-ranking-methods-colley-massey-elo"
                        className="sm:w-auto w-full border border-gray-200 p-4 shadow rounded-lg lg:mx-0 mt-4 sm:mt-6 flex justify-center items-center cursor-pointer hover:shadow-lg"
                    >
                        <ChartBarIcon className="h-7 w-7 mr-2 text-badger-purple" />
                        <span className="mr-2 text-center text-black w-full">Sports Ranking Methods: Colley, Massey, and ELO Ratings</span>
                    </Link>
                </GridDiv>
                <GridDiv
                    widthClasses="w-full sm:w-1/2"
                >
                    <Link
                        to="/sports-models/monte-carlo-simulation-sports-prediction"
                        className="sm:w-auto w-full border border-gray-200 p-4 shadow rounded-lg lg:mx-0 mt-4 sm:mt-6 flex justify-center items-center cursor-pointer hover:shadow-lg"
                    >
                        <DocumentTextIcon className="h-7 w-7 mr-2 text-badger-yellow" />
                        <span className="mr-2 text-center text-black w-full">Monte Carlo Simulations and Sports Predictions</span>
                    </Link>
                </GridDiv>
                <GridDiv
                    widthClasses="w-full sm:w-1/2"
                >
                    <Link
                        to="/sports-models/golf"
                        className="sm:w-auto w-full border border-gray-200 p-4 shadow rounded-lg lg:mx-0 mt-4 sm:mt-6 flex justify-center items-center cursor-pointer hover:shadow-lg"
                    >
                        <FlagIcon className="h-7 w-7 mr-2 text-badger-red" />
                        <span className="mr-2 text-center text-black w-full">How To Predict Golf Tournaments</span>
                    </Link>
                </GridDiv>
            </GridDivContainer>
        </PageBody>
    );
};
